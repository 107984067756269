//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@include googleFont((
  "name": "Roboto",
  "folder": "Roboto",
  "files": "Roboto",
  "weights": (300, 400, 700)
));