.article-card {
  display: flex;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  &__image {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    max-width: 100%;

    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
      width: 100%;
    }

    img {
      width: 100%;
    }

    a {
      position: absolute;
      bottom: -1px;
      left: -1px;
      background-color: $dark;
      padding: 15px 30px;
      z-index: 2;
      text-transform: uppercase;
      text-decoration: none;
      color: $white;
      font-size: 14px;
      border-top-right-radius: 20px;

      svg {
        margin-left: 15px;
      }

      &:hover {
        color: $success;
      }
    }
  }

  &.article-card-lg {
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .article-card__text {
      position: relative;
      z-index: 1;
      width: 60%;
      margin-right: -10%;

      @include media-breakpoint-down(sm) {
        order: 1;
        margin-top: 15px;
        margin-right: 0;
        width: 100%;
      }

      &__category {
        max-width: 70%;
        background-color: #292639;
        padding: 5px 25px;
        border-radius: 20px;
        margin: 20px 0 40px;

        @include media-breakpoint-only(lg) {
          margin-bottom: 20px;
        }

        .h5 {
          margin: 0;
          font-size: 24px;
          font-weight: 400;

          span {
            @include gradientColor;
          }
        }
      }

      &__title {
        * {
          font-size: 48px;
          line-height: 60px;
          font-weight: 700;
          margin-bottom: 40px;
          color: $white;
          text-decoration: none;
          display: block;

          @include media-breakpoint-down(lg) {
            font-size: 32px;
            line-height: normal;
            margin-bottom: 20px;
          }

          &:hover {
            color: $success;
          }
        }
      }

      &__text {
        margin-right: 20%;

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }

        p, span {
          font-size: 18px;
          line-height: 36px;
          font-weight: 400;
        }
      }
    }

    .article-card__image {
      max-width: 60%;

      @include media-breakpoint-down(lg) {
        max-width: 50%;
      }

      @include media-breakpoint-down(sm) {
        order: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
      }
    }
  }

  &.article-card-sm {
    align-items: center;

    .article-card__text {
      flex: 1;
    }

    .article-card__image {
      display: block;
      max-width: 180px;

      img {
        width: 100%;
      }
    }

    &:hover {
      .article-card__text {
        a {
          color: $success;
        }
      }
    }

    &.image-left {
      .article-card__text {
        order: 1;
        padding-left: 15px;

        &__title {
          * {
            font-size: 18px;
            line-height: unset;
            color: $white;
            text-decoration: none;

            &:hover {
              color: $success;
            }
          }
        }
      }

      .article-card__image {
        order: 0;
      }
    }
  }
}