.price-card {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__left {
    background-color: #3d3a4e;
    padding: 10px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    @include media-breakpoint-down(md) {
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0;
    }

    display: flex;
    align-items: flex-start;
  }

  &__right {
    background-color: #292639;
    padding: 10px 20px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    @include media-breakpoint-down(md) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 12px;
    }
  }

  &__logo {
    width: 50px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }

  &__info {
    flex: 1;

    & > * {
      display: flex;

      b {
        flex: 1;
        text-align: right;
        margin-left: 60px;
      }
    }

    .h5 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      font-size: 16px;
      font-weight: 300;

      b {
        font-weight: 300;
      }
    }

    .color {
      color: $success;
    }
  }

  &__platform {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &__logo {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 4px;
      margin-right: 10px;

      img {
        width: 100%;
      }
    }

    &__text {
      span {
        font-size: 12px;
        line-height: 14px;
        display: block;
        text-transform: uppercase;
      }

      a {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0;
        color: $white;
        text-decoration: none;
        display: block;
      }
    }

    &:hover {
      cursor: pointer;

      a {
        color: $success;
      }
    }
  }
}