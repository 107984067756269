.exchange-card {
  background: #292639;
  padding: 30px 20px;
  border-radius: 12px;

  form {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
    }

    .form-item {
      display: flex;
      flex: 1;
      margin: 0 10px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      & > div {
        position: relative;
      }

      .item-currency {
        flex: 1;
        margin-right: 15px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }

      .item-amount {
        max-width: 25%;

        @include media-breakpoint-down(lg) {
          max-width: 35%;
        }

        @include media-breakpoint-down(sm) {
          max-width: none;
        }

        @keyframes glow-animation-up {
          0%   {
            color: $white;
          }
          50%  {
            color: $success;
          }
          100% {
            color: $white;
          }
        }

        @keyframes glow-animation-down {
          0%   {
            color: $white;
          }
          50%  {
            color: $danger;
          }
          100% {
            color: $white;
          }
        }

        .amount-up {
          animation-name: glow-animation-up;
          animation-duration: 3s;
          animation-iteration-count: infinite;
        }

        .amount-down {
          animation-name: glow-animation-down;
          animation-duration: 3s;
          animation-iteration-count: infinite;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 10px 10px;
      }

      label {
        position: absolute;
        background: #292639;
        font-size: 12px;
        font-weight: 400;
        top: -8px;
        left: 15px;
        padding: 0 5px;
      }

      input, select {
        background: transparent;
        border: 2px solid #3d3a4e;
        border-radius: 10px;
        padding: 10px 20px;
      }

      input {
        width: 130px;
      }

      .select2 {

        //@keyframes glow-animation {
        //  0%   {
        //    border-color: #3d3a4e;
        //  }
        //  50%  {
        //    border-color: #209282;
        //  }
        //  100% {
        //    border-color: #3d3a4e;
        //  }
        //}

        .selection .select2-selection {
          border: 2px solid #3d3a4e;
          display: flex;
          padding: 10px 20px;
          border-radius: 10px;
          //animation-name: glow-animation;
          //animation-duration: 3s;
          //animation-iteration-count: infinite;

          &:hover {
            cursor: pointer;
          }
        }

        &.select2-container--open {
          .selection .select2-selection {
            background: #3d3a4e;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      select:not(.select2) {
        display: none;
      }
    }

    .form-separator {
      margin: 0 5px;

      svg {
        color: $warning;
      }
    }

    .btn {
      margin: 0 10px;
      border-radius: 10px;
      padding: 10px 45px;
      color: $white;
    }
  }
}

.select2-container {
  margin-top: -5px;

  .select2-dropdown {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #525279;
    padding: 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    input {
      width: 100%;
      background: transparent;
      box-shadow: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #757594;
      margin-bottom: 15px;
    }

    .select2-results {
      &__options {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 15px;

          span img {
            margin-right: 10px;
          }

          &:hover {
            cursor: pointer;
            color: $success;
          }
        }
      }
    }
  }
}