.copy-picture {
  &__inlay {
    display: flex;
    align-items: center;
    padding: 0 40px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__text {
    flex: 1;
    padding-right: 60px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      margin-bottom: 30px;
      order: 0;
    }

    .copy-picture-number {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      border-radius: 7px;

      background: rgb(30,192,122);
      background: -moz-linear-gradient(left,  rgba(30,192,122,1) 0%, rgba(32,144,130,1) 100%);
      background: -webkit-linear-gradient(left,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 100%);
      background: linear-gradient(to right,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ec07a', endColorstr='#209082',GradientType=1 );
    }

    .h3 {
      font-size: 36px;
      line-height: initial;
      font-weight: 700;
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        font-size: 32px;
        margin-bottom: 30px;
      }

      span {
        @include gradientColor;
      }
    }

    .h4 {
      font-size: 24px;
      line-height: normal;
      font-weight: 700;
      margin-bottom: 40px;
    }

    & > span {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 60px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    flex: 1;

    @include media-breakpoint-down(md) {
      order: 1;
    }

    img {
      max-width: 100%;
    }
  }

  &.picture-left {
    .copy-picture__text {
      order: 1;
      padding-left: 60px;
      padding-right: 0;

      @include media-breakpoint-down(md) {
        order: 0;
        padding: 0;
      }
    }

    .copy-picture__image {
      order: 0;

      @include media-breakpoint-down(md) {
        order: 1;
      }
    }
  }
}