.top-table-section {
  &__inlay {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    & > div {
      flex: 1;
    }

    .top-text {
      padding-left: 70px;

      @include media-breakpoint-down(md) {
        padding-left: 40px;
      }

      .h4 {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 40px;

        span {
          @include gradientColor;
        }
      }

      & > span {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}