.newsletter-card {
  background-image: url('../../assets/images/newsletter-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 60px;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 20px;
  }

  &__text {
    flex: 1;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    * {
      font-size: 40px;
      line-height: 48px;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: normal;
      }

      span {
        @include gradientColor;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;

    @include media-breakpoint-down(md) {
      align-items: flex-start;
    }

    .form-label {
      display: none;
    }

    form {
      text-align: right;
      width: 70%;

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: left;
      }

      input {
        width: 100%;
        background: none;
        margin-bottom: 15px;
        font-size: 14px;
        padding: 12px 20px;
        border-radius: 10px;
        font-weight: 300;

        &::-webkit-input-placeholder {
          color: $white;
        }
        &::-moz-placeholder {
          color: $white;
        }
        &:-ms-input-placeholder {
          color: $white;
        }
        &:-moz-placeholder {
          color: $white;
        }

        &:focus {
          background: transparent;
        }
      }

      .btn {
        background: #463971;
        border-color: #463971;
        padding: 10px 35px;
        border-radius: 10px;
      }
    }
  }

  &__success {
    min-width: 100%;
    margin-top: 15px;

    * {
      font-size: 28px;
      line-height: normal;
      font-weight: 600;
      text-align: right;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: normal;
      }

      span {
        @include gradientColor;
      }
    }
  }
}