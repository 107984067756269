.social-media-card {
  background-color: #2e2744;
  padding: 10px 20px 10px 10px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-down(md) {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 7px;
  }

  &__icon {
    background-color: #443f5c;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 10px;

    @include media-breakpoint-down(md) {
      width: 35px;
      height: 35px;
    }
  }

  &__text {
    .h5 {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      margin-bottom: 0;
      color: $white;
      text-decoration: none;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      &:hover {
        color: $success;
      }
    }

    span {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      text-transform: uppercase;
      display: block;
    }
  }
}