.header {
  .top-news {
    margin-top: 15px;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .main-nav {

    .navbar {
      justify-content: flex-start;
    }

    .navbar-brand {
      margin-right: 45px;

      img {
        @include media-breakpoint-down(sm) {
          max-width: 100px;
        }
      }

      @include media-breakpoint-down(lg) {
        order: 1;
        flex: 1;
      }

      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }

    .navbar-toggler {
      color: $white;
      order: 0;

      &:focus,
      &:visited {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .navbar-collapse {
      @include media-breakpoint-down(lg) {
        order: 4;
      }

      .whitepaper-box {
        margin-top: 15px;

        .btn {
          display: block;
          width: 100%;
        }

        .dropdown-menu {
          position: static;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    .navbar-nav {
      flex: 1;
      padding-top: 10px;

      .nav-item:nth-last-child(2) {
        flex: 1;
      }

      .nav-link {
        font-size: 14px;
        color: $white;
        margin: 0 15px;
        text-transform: uppercase;

        &.disabled {
          opacity: 0.5;
        }

        &:hover {
          @include gradientColor;
        }
      }

      #langSwitch {
        img {
          border-radius: 50%;
          max-width: 25px;
        }
      }
    }

    .options-nav {
      display: flex;
      align-items: stretch;
      justify-content: flex-end;

      @include media-breakpoint-down(lg) {
        order: 2;
        flex: 1;
      }

      .btn {
        margin-right: 15px;
        padding: 6px 35px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .whitepaper-box {
        position: relative;
        display: flex;
        align-items: stretch;

        @include media-breakpoint-down(md) {
          display: none;
        }

        .dropdown-item {
          text-align: center;
          justify-content: center;
          margin-bottom: 10px;

          span {
            margin: 0;
          }
        }
      }

      .currency-price {
        display: flex;
        align-items: center;
        background-color: #3d3a4e;
        padding: 5px 15px 5px 5px;
        border-radius: 8px;
        text-decoration: none;

        @include media-breakpoint-down(md) {
          min-width: 170px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 10px;
          flex: 1;
        }

        &__logo {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        &__title {
          margin-right: 10px;

          h5 {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 0;
            color: $white;

            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
          }
        }

        &__price {
          span {
            color: $success;
            font-weight: 300;
            font-size: 16px;

            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.dropdown-menu {
  background: #292639;

  li a {
    color: #ffffff;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 14px;
      margin-left: 15px;
      opacity: 0.8;
      color: $white;
    }

    &:hover {
      background: transparent;

      span {
        opacity: 1;
      }
    }
  }
}

.top-news {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #292639;
  border-radius: 8px;
  overflow: hidden;

  &__title {
    padding: 10px 20px;
    background-color: #333044;

    * {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;

      span {
        @include gradientColor;
      }
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    flex: 1;

    &__item {
      display: inline-flex;
      align-items: center;

      .news-item-text {
        margin-right: 60px;

        &.price-up {
          a {
            color: $success;
          }
        }

        &.price-down {
          a {
            color: $danger;
          }
        }

        svg {
          transform: translateY(-2px);
        }
      }

      span, a {
        margin-bottom: 0;
        color: $white;
        text-decoration: none;
      }

      &:hover {
        a {
          color: $success;
        }
      }
    }
  }
}