@mixin gradientColor {
  color: $success;
  background-image: -webkit-linear-gradient(65deg, #1af490, #1db39b, #1af490);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include media-breakpoint-down(md) {
    color: $success;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
}