$font-family-base: 'Roboto', sans-serif;

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// Colors
//---------------------------------------------

$primary:    #0d6efd;
$secondary:  #6c757d;
$success:    #1af390;
$info:       #0dcaf0;
$warning:    #f2c94c;
$danger:     #ff4465;
$light:      #f8f9fa;
$dark:       #211e34;
$black:      #000000;
$white:      #ffffff;

$body-color: $white;
$body-bg: $dark;

//---------------------------------------------
// Container Width
//---------------------------------------------

// Media queries breakpoints
// -------------------------
// Breakpoints are defined as a map of (name: minimum width), order from small to large
$custom-grid-breakpoints: (
  //xs: 0,					// PHONE PORTRAIT		0	- 543
  //sm: em(544px, 16px),	// PHONE LANDSCAPE		544	- 767
  //md: em(768px, 16px),	// TABLET PORTRAIT		768	- 991
  //lg: em(992px, 16px),	// TABLET LANDSCAPE		992	- 1199
  //xl: em(1200px, 16px),	// DESKTOP				1200 ~
        xs: 0, // PHONE PORTRAIT		0	- 543
        sm: 544px, // PHONE LANDSCAPE		544	- 767
        md: 768px, // TABLET PORTRAIT		768	- 991
        lg: 992px, // TABLET LANDSCAPE		992	- 1199
        xl: 1200px // DESKTOP				1200 ~
);