.title-section {
  margin: 80px 0;
  overflow: hidden;
  max-width: 100%;

  @include media-breakpoint-down(md) {
    margin: 40px 0;
  }

  &__inlay {
    max-width: 73%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    & > span {
      font-size: 24px;
      line-height: 36px;
      display: block;
      left: 0;
      right: 0;
      margin: auto;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .h2 {
    font-size: 38px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 60px;
    display: inline-block;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      @include gradientColor;
    }
  }

  .h3 {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;

    span {
      @include gradientColor;
    }
  }
}