.footer {
  padding-bottom: 60px;

  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
  }

  &__brand {
    &__logo {
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }

    &__copyright {
      padding-left: 50px;
      max-width: 60%;

      @include media-breakpoint-down(md) {
        padding: 0;
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        opacity: 0.8;
      }
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    &__title {
      * {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &__list {
      padding-top: 20px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin-bottom: 10px;

          a, span {
            font-size: 14px;
            text-decoration: none;
            color: $white;
            opacity: 0.8;

            svg {
              margin-right: 7px;
            }
          }

          .h5 {
            font-size: 14px;

            span.price-up {
              color: $success;
            }

            span.price-down {
              color: $danger;
            }
          }

          a:hover {
            opacity: 1;
            color: $success;
          }
        }
      }
    }
  }
}