.top-table {
  background-color: #252237;
  padding: 40px;
  border-radius: 12px;

  &.top-table-thin {
    padding: 20px 40px;
    margin-bottom: 15px;
  }

  @include media-breakpoint-only(lg) {
    min-width: 65%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-bottom: 20px;
    padding: 25px;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #3b394b;
    flex-wrap: wrap;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .h5 {
      font-size: 16px;
    }

    &__avatar {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    &__title {
      flex: 1;

      @include media-breakpoint-down(xs) {
        min-width: 80%;
        margin-bottom: 20px;
      }
    }

    &__price {
      margin-right: 60px;

      @include media-breakpoint-down(md) {
        margin-right: 30px;
      }

      @include media-breakpoint-down(xs) {
        flex: 1;
      }
    }

    &__status {
      margin-right: 60px;

      @include media-breakpoint-down(md) {
        margin-right: 30px;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }

      .h5 {
        &.price-up {
          color: $success;
        }

        &.price-down {
          color: $danger;
        }

        svg {
          margin-top: -3px;
          margin-left: 7px;
        }
      }

      span {
        display: block;
        font-size: 11px;
      }
    }

    &__cta {

      .btn {
        min-width: 94px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
        min-width: 100%;

        .btn {
          display: block;
        }
      }
    }
  }
}