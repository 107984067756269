.modal {
  &.show {
    display: flex !important;
    align-items: center;
    justify-content: center;

    .modal-dialog {
      min-width: 60%;
    }
  }
}

.modal-content {
  background-color: #2e2744;
  border-radius: 13px;

  .btn-close {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    margin: auto;
    color: $white;
  }

  .h4 {
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 15px;

    span {
      @include gradientColor;
    }
  }
}