//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "mixins/mixins";
@import "mixins/responsive";
@import "typography";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
}

a {
  color: $success;
  transition: 300ms ease-in-out;

  &:hover {
    color: #1db39b;
  }
}

h1, h2, h3, h4, h5 {
  margin-bottom: 0;
}

.margin-section {
  padding: 80px 0;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
}

.btn-gradient-success {
  background: rgb(30,192,122);
  background: -moz-linear-gradient(left,  rgba(30,192,122,1) 0%, rgba(32,144,130,1) 100%);
  background: -webkit-linear-gradient(left,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 100%);
  background: linear-gradient(to right,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ec07a', endColorstr='#209082',GradientType=1 );
  border: none;
  border-radius: 8px;
  color: $white;
  transition: 300ms ease-in-out;

  &:hover {
    background: rgb(30,192,122); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(30,192,122,1) 0%, rgba(32,144,130,1) 47%);
    background: -webkit-linear-gradient(left,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 47%);
    background: linear-gradient(to right,  rgba(30,192,122,1) 0%,rgba(32,144,130,1) 47%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ec07a', endColorstr='#209082',GradientType=1 );
    color: $white;
  }
}

.articles-list {
  .article-card {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #383549;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

.exchange-section {
  max-width: 100%;
  
  .exchange-footnote {
    display: flex;
    padding: 15px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    span {
      font-size: 12px;
      //opacity: 0.8;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }

      svg {
        margin: 0 7px;
      }
    }

    span:first-child {
      flex: 1;
    }

    span:last-child {
      max-width: 40%;
      text-align: right;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        text-align: left;
      }
    }

    .info-section {
      position: relative;

      .tooltip {
        position: absolute;
        top: 30px;
        right: 0;
        min-width: 300px;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        padding: 20px;
        text-align: left;
        border-radius: 12px;
        background-color: #443f5c;
        pointer-events: none;
        transition: 300ms ease-in-out;
      }

      &:hover {
        cursor: pointer;

        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.timer {
  display: flex;

  .h4 {
    font-size: 60px;
    font-weight: 300;
    font-style: italic;

    &:first-child {
      margin-right: 30px;
    }

    span {
      font-size: 12px;
      display: block;
      font-weight: 700;
      padding-left: 10px;
      opacity: 0.8;
    }
  }
}

.amount-element {
  .h4 {
    font-size: 60px;
    font-weight: 300;
    font-style: italic;
    padding-top: 15px;

    sup {
      color: $success;
      font-size: 30px;
      display: inline-block;
      transform: translateY(-10px);
    }

    span {
      font-size: 20px;
      opacity: 0.8;
    }
  }
}



//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/header";
@import "components/social-media-card";
@import "components/price-card";
@import "components/copy-picture";
@import "components/article-card";
@import "components/newsletter";
@import "components/exchange-card";
@import "components/top-table";
@import "components/footer";
@import "components/modal";

//---------------------------------------------
// 4.Sections
//---------------------------------------------

@import "sections/title-section";
@import "sections/top-table-section";